<template>
  <adding-form></adding-form>
</template>

<script>
import NewAdvertisingBannerFormVue from '../../components/Forms/advertisingBanner/NewAdvertisingBannerForm.vue';

export default {
  name: 'NewAdvertisingBannerPage',
  components: {
    addingForm: NewAdvertisingBannerFormVue,
  },
};
</script>
