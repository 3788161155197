import axios from 'axios';
import urls from '@/utils/constants/index';

const { PUSH_MESSAGE_URL } = urls;

const postPushMessage = ({ commit }, data) => new Promise((resolve, reject) => {
  axios.post(`${PUSH_MESSAGE_URL}`, data.messageData, { headers: { 'admin-token': data.token } })
    .then(() => {
      commit();
    })
    .catch((error) => {
      console.error(error.message);
      reject(error);
    });
});

export default {
  postPushMessage,
};
