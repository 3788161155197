<template>
  <form
    id="form"
    @submit.prevent="addCheckpoint"
    class="form page__content">
    <map-vue
      :coordinates="coordinates || '50.470773, 30.500560'"
    ></map-vue>

    <div class="form__container">
      <label
        for="coordinates"
        class="text-white">Координати чекпоінту*</label>
      <input
        id="coordinates"
        class="input"
        type="text"
        @focus="resize"
        v-model="coordinates"
        placeholder="50.470773, 30.500560"
        :class="{'input--error': coordinates.length === 0}"
        required>
    </div>
    <section class="form_row">
      <div class="form__section">
        <label
          for="notificationTitle"
          class="text-white">Заголовок сповіщення*</label>
          <textarea
            name="notificationTitle"
            id="notificationTitle"
            @keyup="changeTextareaSize('notificationTitle')"
            class="text textarea"
            v-model="notificationTitle"
            placeholder="ПОПЕРЕДУ КАМЕРА (макс. довжина: 500 символів)"
            cols="30"
            rows="2"
            maxlength="500"
            :class="{'input--error': notificationTitle.length === 0}"
            required></textarea>
      </div>
      <div class="form__section">
        <label
          for="notificationText"
          class="text-white">Текст сповішення*</label>
          <textarea
            name="notificationText"
            id="notificationText"
            class="text textarea"
            @keyup="changeTextareaSize('notificationText')"
            v-model="notificationText"
            placeholder="БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 50КМ/ГОД (макс. довжина: 500 символів)"
            cols="30"
            rows="2"
            maxlength="500"
            :class="{'input--error': notificationText.length === 0}"
            required></textarea>
      </div>
    </section>
    <div class="flex">
      <div class="form__container">
        <p class="text-white">Тип</p>
        <section class="form__container--type">
          <input class="radio" id="50км" type="radio" v-model="type"
                 @click="changeNotificationText(50)" value=50>
          <label for="50км" class="text text-white radio--text">50км/ч</label>
          <input class="radio" id="110км" type="radio" v-model="type"
                 @click="changeNotificationText(110)" value=110>
          <label for="110км" class="text text-white radio--text">110км/ч</label>
          <input class="radio" id="poliseStation" type="radio" v-model="type"
                 @click="changeNotificationText(0)" value=0>
          <label for="poliseStation" class="text text-white radio--poliseStation">
            Пост Поліції</label>
        </section>
      </div>
      <notification-preview
        :imageSrc="getImageSrc"
        :notificationTitle="notificationTitle"
        :notificationText="notificationText" />
    </div>
    <p class="text-red">{{ error }}</p>
    <p class="text-white--option">* - Обов'язкові поля</p>
    <p class="text-white--option">Максимальна довжина: 500 символів</p>
    <button class="text-white button-submit" type="submit">Надіслати</button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MapVue from '../../../utils/UI/Map.vue';
import NotificationPreviewVue from '../../../utils/UI/NotificationPreview.vue';

export default {
  name: 'NewCheckpointForm',
  components: {
    mapVue: MapVue,
    notificationPreview: NotificationPreviewVue,
  },
  data() {
    return {
      type: 50,
      coordinates: '',
      notificationText: 'БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 50КМ/ГОД',
      notificationTitle: '',
      error: '',
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'getToken',
    }),
    getCoordinates() { return this.coordinates.trim(); },
    getNotificationText() { return this.notificationText; },
    getNotificationTitle() { return this.notificationTitle; },
    getType() { return this.type; },
    getImageSrc() {
      return `${process.env.VUE_APP_API_URL}/image/speedPoints?type=${this.type}`;
    },
    getNewCheckpoint() {
      return {
        type: 'camera',
        data: {
          coordinates: this.getCoordinates,
          title: this.getNotificationTitle,
          text: this.getNotificationText,
          speed_type: this.getType,
        },
      };
    },
  },
  methods: {
    changeNotificationText(type) {
      switch (type) {
        case 0:
          this.notificationText = 'БУДЬТЕ ОБАЧНІ, ПОСТ ПОЛІЦІЇ';
          break;
        case 50:
          this.notificationText = 'БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 50КМ/ГОД';
          break;
        case 110:
          this.notificationText = 'БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 110КМ/ГОД';
          break;
        default:
          this.notificationText = 'БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 50КМ/ГОД';
          break;
      }
      return this.notificationText;
    },
    ...mapActions({
      post: 'postCheckpointToAPI',
    }),
    addCheckpoint() {
      const data = {
        checkpoint: this.getNewCheckpoint,
        token: this.getToken,
      };
      if (this.coordinates.length !== 0
          && this.notificationText.length !== 0
          && this.notificationTitle.length !== 0) {
        this.post(data);
        // eslint-disable-next-line no-alert
        alert('новий чекпоїнт створено');
        this.reset();
      }
    },
    reset() {
      document.getElementById('form').reset();
      this.coordinates = '';
      this.notificationText = 'БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 50КМ/ГОД';
      this.notificationTitle = '';
      this.type = 50;
    },
    changeTextareaSize(tagId) {
      const textarea = document.getElementById(tagId);
      if (textarea.scrollTop > 0) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.form__section
  margin-bottom: 1.88rem
.form__container
  max-width: 100%
</style>
