import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
  checkpoints: [],
  checkpoint: {},
  searchValue: '',
  pagesNumbers: 0,
  localOffset: 0,
};

export default {
  state,
  getters,
  mutations,
  actions,
};
