<template>
  <form @submit.prevent="submit" class="form--login">
    <div class="logo-container">
      <img class="logo-container__image"
            src="../../../public/images/logo-small.svg" alt="logo image">
      <img class="logo-container__name"
            src="../../../public/images/company-name.svg" alt="speedua">
    </div>
    <section class="form__container">
      <label for="login" class="text-white">Логін*</label>
      <input
        id="login"
        type="text"
        class="input"
        v-model="login"
        placeholder="admin"
        :class="{'input--error': login.length === 0}"
        required>
    </section>
    <section class="form__container">
      <label for="password" class="text-white">Пароль*</label>
      <input
        id="passsword"
        class="input"
        type="password"
        v-model="password"
        @focusout="checkPassword"
        placeholder="********"
        :class="{'input--error': password.length === 0}"
        required>
    </section>
    <p class="text-white">* - Обов'язкові поля</p>
    <p class="text-red">{{ error }}</p>
    <button type="submit" class="text-white button-submit">Вхід</button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import { validPassword } from '@/utils/validator/index';

export default {
  name: 'Login',
  data() {
    return {
      login: '',
      password: '',
      error: '',
      loginLength: 1,
    };
  },
  methods: {
    ...mapActions({
      saveAdminIntoState: 'saveAdminIntoState',
    }),
    async submit() {
      this.checkPassword();
      const data = {
        login: this.login,
        password: this.password,
      };
      if (this.error === 'Введені дані не вірні' || this.error === '') {
        try {
          await this.saveAdminIntoState(data);
          setTimeout(() => {
            this.$router.push('/checkpoint/new');
          }, 500);
        } catch (error) {
          if (this.error !== '') {
            this.$router.push('/not-found');
          }
        }
      }
    },
    checkPassword() {
      const validate = validPassword(this.password);
      if (!validate || this.password.length <= 8) {
        this.error = 'Введені дані не вірні';
      } else this.error = '';
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../assets/main.sass'

.logo-container
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 3rem
  &__image
    margin-bottom: 2rem

.form--login
  padding-top: 4rem
  padding-bottom: 8rem
  margin: auto
  height: 54vh
  width: 67%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: $dark-gray

.form__container
  width: 44%

.input
  width: 100%
  line-height: 2rem

.button-submit
  margin: 0
  margin-top: 2rem

.text-white
  text-align: center
  display: block

@media (max-width: 767px)
  .form__container
    width: 14rem

@media  (max-width: 818px) and (min-width: 0)
  .logo-container__name
    width: 80%

</style>
