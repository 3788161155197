<template>
<div class="container nemu-nav">
  <div class="last">
    <router-link
      v-if="localPageNumber > 1"
      @click="getLastPoints"
      class="menu-nav__button menu-nav__link--last" type="button"
      :to="{ name: 'AdertisingBannersList', params: { pageNumder: localPageNumber - 1 } }"
    >Попередня</router-link>
  </div>
  <div class="list">
    <router-link
        v-if="localPageNumber === 1"
        class="menu__link text-white menu-nav__link"
        exact-active-class="menu-nav__link--active"
        @click="getPoints(1)"
        :to="{ name: 'AdertisingBannersList', params: { pageNumder: 1 } }"
      >1</router-link>
      <router-link
        class="menu__link text-white menu-nav__link"
        exact-active-class="menu-nav__link--active"
        v-for="n in pages"
        :key="n"
        @click="getPoints(n-1)"
        :to="{ name: 'AdertisingBannersList', params: { pageNumder: n } }"
      >{{ n }}</router-link>

  </div>
  <div class="next">
    <router-link
      v-if="localPageNumber < pages"
      @click="getNextPionts"
      :to="{ name: 'AdertisingBannersList', params: { pageNumder: localPageNumber + 1 } }"
      class="menu-nav__button menu-nav__link--right" type="button"
    >Наступна</router-link>

  </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { LIMIT } from '@/utils/constants/index';

export default {
  name: 'BannerPagesNav',
  computed: {
    ...mapGetters({
      pages: 'getBannerPagesNumbers',
      banners: 'getAllBannersFromState',
      token: 'getToken',
      searchValue: 'getBannerSearchValue',
      localOffset: 'getBannerLocalOffset',
    }),
    minLink() {
      return this.localOffset - LIMIT;
    },
    maxLink() {
      return this.localOffset + LIMIT;
    },
    localPageNumber() {
      return +this.$route.params.pageNumder;
    },
    getData(offset) {
      return {
        token: this.token,
        offsetValue: offset,
        searchValue: this.searchValue,
      };
    },
  },
  methods: {
    ...mapActions({
      getAllFromAPI: 'searchBanners',
    }),
    ...mapMutations({
      setOffset: 'setBannerLocalOffset',
    }),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async getLastPoints() {
      const newOffset = (this.localOffset - 1) * LIMIT;
      const data = {
        token: this.token,
        offsetValue: newOffset,
        searchValue: this.searchValue,
      };
      await this.getAllFromAPI(data);
      this.scrollToTop();
    },
    async getNextPionts() {
      const newOffset = (this.localOffset + 1) * LIMIT;
      const data = {
        token: this.token,
        offsetValue: newOffset,
        searchValue: this.searchValue,
      };
      await this.getAllFromAPI(data);
      this.scrollToTop();
    },
    async getPoints(offset) {
      const data = {
        token: this.token,
        offsetValue: offset * LIMIT,
        searchValue: this.searchValue,
      };
      try {
        await this.getAllFromAPI(data);
      } catch (error) {
        this.$router.push({ name: 'NotFoundPage' });
      }
      this.scrollToTop();
    },
  },
  async beforeMount() {
    await this.pages;
  },
};
</script>
