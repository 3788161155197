import axios from 'axios';
import urls from '../../utils/constants/index';

const HEADER_TOKEN = { 'admin-token': 'iLw3Y3-iHK2-JqrY-J4At-B7mIgGGlXc5h2H' };
const { AUTH_URL } = urls;

const saveAdminIntoState = ({ commit }, adminData) => new Promise((resolve, reject) => {
  axios.post(AUTH_URL, adminData, { headers: HEADER_TOKEN })
    .then((response) => {
      const { token } = response.data;
      commit('setToken', token);
      axios.defaults.headers.common.Authorization = token;
      resolve(response);
    })
    .catch((error) => {
      console.error(error.message);
      reject(error);
    });
});

const logout = ({ commit }) => new Promise(() => {
  commit('removeToken');
  delete axios.defaults.headers.common.Authorization;
});

const setTokenToState = ({ commit }, token) => {
  commit('setToken', token);
};

export default {
  saveAdminIntoState,
  setTokenToState,
  logout,
};
