const setNewBannerToState = (state, banner) => {
  state.banners.push(banner);
};

const setAllBannersToState = (state, banners) => {
  state.banners = banners.advertisement;
};

const setBannerToSate = (state, banner) => {
  state.banner = banner;
};

const deleteBannerFromState = (state, id) => {
  const { banners } = state;
  for (let index = 0; index < banners.length; index += 1) {
    const item = banners[index];
    if (item.id === +id) banners.splice(index, 1);
  }
};

const updateBannerInState = (state, data) => {
  const { banners } = state;
  for (let index = 0; index < banners.length; index += 1) {
    const item = banners[index];
    if (+item.id === +data.id) {
      banners[index] = data.banner;
      banners[index].id = data.id;
    }
  }
};

const setBannerLocalOffset = (state, offset) => {
  state.localOffset = offset;
};

const setBannerSearchValue = (state, searchValue) => {
  state.searchValue = searchValue;
};

const setBannerPagesNumbers = (state, pagesNumbers) => {
  state.pagesNumbers = pagesNumbers;
};

export default {
  setNewBannerToState,
  setBannerToSate,
  setAllBannersToState,
  deleteBannerFromState,
  updateBannerInState,
  setBannerPagesNumbers,
  setBannerSearchValue,
  setBannerLocalOffset,
};
