<template>
  <main class="page">
    <login-form></login-form>
  </main>
</template>

<script lang="js">
import LoginForm from '../components/Forms/Login.vue';

export default {
  name: 'LoginPage',
  components: {
    loginForm: LoginForm,
  },
};
</script>

<style lang="sass" scoped>
@import '../assets/main'

.page
  width: 100%
  height: 100vh
  display: flex
  background-color: $gray
</style>
