const getAllBanners = (state) => state.banners;
const getBanner = (state) => state.banner;
const getBannerPagesNumbers = (state) => state.pagesNumbers;
const getBannerSearchValue = (state) => state.searchValue;
const getBannerLocalOffset = (state) => state.localOffset;

export default {
  getAllBanners,
  getBanner,
  getBannerLocalOffset,
  getBannerSearchValue,
  getBannerPagesNumbers,
};
