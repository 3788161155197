import axios from 'axios';
import urls from '@/utils/constants/index';

const { POINT_URL } = urls;

const postCheckpointToAPI = ({ commit }, data) => new Promise((resolve, reject) => {
  axios.post(`${POINT_URL}/add`, data.checkpoint, { headers: { 'admin-token': data.token } })
    .then(() => {
      commit('setNewCheckpointToState', data.checkpoint);
    })
    .catch((error) => {
      reject(error);
    });
});

const getCheckpointFromAPIById = ({ commit }, data) => new Promise((resolve, reject) => {
  axios.get(`${urls.POINT_URL}/${data.id}`, { headers: { 'admin-token': data.token } })
    .then((response) => {
      commit('setCheckpointToState', response.data);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getAllCheckpointsFromAPI = ({ commit }, data) => new Promise((resolve, reject) => {
  axios.get(`${POINT_URL}/?limit=100&offset=${data.offsetValue}`, { headers: { 'admin-token': data.token } })
    .then((response) => {
      commit('setSearchValue', data.searchValue);
      commit('setAllCheckpointsToState', response.data.camera);
      commit('setPagesNumbers', response.data.numberPages);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateCheckpoint = ({ commit }, data) => new Promise(() => {
  axios.patch(`${POINT_URL}/${data.id}`, data.checkpoint, { headers: { 'admin-token': data.token } })
    .then(() => {
      commit('updateCheckpoint', data);
    })
    .catch((error) => {
      console.error(error.message);
    });
});

const deleteCheckpointFromAPIById = ({ commit }, data) => new Promise(() => {
  axios.delete(`${POINT_URL}/${data.id}`, { headers: { 'admin-token': data.token } })
    .then(() => {
      commit('deleteCheckpointFromState', data.id);
    })
    .catch((error) => {
      console.error(error.message);
    });
});

const searchCheckpoint = ({ commit }, data) => new Promise((resolve, reject) => {
  axios.get(`${POINT_URL}?limit=100&offset=${data.offsetValue}&search=${data.searchValue}`, { headers: { 'admin-token': data.token } })
    .then((response) => {
      if (data.offsetValue !== 0) {
        commit('setCheckpointLocalOffset', +data.offsetValue / 100);
      } else commit('setCheckpointLocalOffset', 0);
      commit('setCheckpointSearchValue', data.searchValue);
      commit('setAllCheckpointsToState', response.data.camera);
      commit('setCheckpointPagesNumbers', response.data.numberPages);
      resolve(response);
    })
    .catch((error) => reject(error));
});

export default {
  postCheckpointToAPI,
  getCheckpointFromAPIById,
  getAllCheckpointsFromAPI,
  updateCheckpoint,
  deleteCheckpointFromAPIById,
  searchCheckpoint,
};
