<template>
  <main class="page">
    <div class="page__container">
      <h1 class="title text-white">Сторінку не знайдено</h1>
    </div>
  </main>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>

<style lang="sass" scoped>
@import '../assets/main'

.title
  font-size: 21px

.page__container
  width: 44%
  padding-top: 4rem
  padding-bottom: 8rem
  margin: auto
  height: 54vh
  width: 67%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: $dark-gray

.text-white
  text-align: center
  display: block

@media (max-width: 400px)
  .title
    font-size: 18px
</style>
