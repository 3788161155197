<template>
  <list></list>
</template>

<script>
import AdvertisingBannerListVue from '../../components/Lists/AdvertisingBannerList.vue';

export default {
  name: 'AdvertisingBannersListPage',
  components: {
    list: AdvertisingBannerListVue,
  },
};
</script>
