<template>
<div class="container nemu-nav">
  <div class="last">
    <router-link
      v-if="localPageNumber > 1"
      @click="getLastPoints"
      class="menu-nav__button menu-nav__link--last" type="button"
      :to="{ path: `/checkpoint/edit/${ localPageNumber - 1 }` }"
    >Попередня</router-link>
  </div>
  <div class="list">
      <router-link
        class="menu__link text-white menu-nav__link"
        exact-active-class="menu-nav__link--active"
        v-for="n in pages"
        :key="n"
        @click="getPoints(n-1)"
        :to="{ path: `/checkpoint/edit/${ n }` }"
      >{{ n }}</router-link>

  </div>
  <div class="next">
    <router-link
      v-if="localPageNumber < pages"
      @click="getNextPionts"
      :to="{ path: `/checkpoint/edit/${ localPageNumber + 1 }` }"
      class="menu-nav__button menu-nav__link--right" type="button"
    >Наступна</router-link>

  </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { LIMIT } from '@/utils/constants/index';

export default {
  name: 'PagesNav',
  computed: {
    ...mapGetters({
      pages: 'getCheckpointPagesNumbers',
      token: 'getToken',
      searchValue: 'getCheckpointSearchValue',
      localOffset: 'getCheckpointLocalOffset',
    }),
    minLink() {
      return this.localOffset - LIMIT;
    },
    maxLink() {
      return this.localOffset + LIMIT;
    },
    localPageNumber() {
      return +this.$route.params.pageNumder;
    },
    getData(offset) {
      return {
        token: this.token,
        offsetValue: offset,
        searchValue: this.searchValue,
      };
    },
  },
  methods: {
    ...mapActions({
      getAllFromAPI: 'searchCheckpoint',
    }),
    ...mapMutations({
      setOffset: 'setCheckpointLocalOffset',
    }),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async getLastPoints() {
      const newOffset = (this.localOffset - 1) * LIMIT;
      const data = {
        token: this.token,
        offsetValue: newOffset,
        searchValue: this.searchValue,
      };
      await this.getAllFromAPI(data);
      this.scrollToTop();
    },
    async getNextPionts() {
      const newOffset = (this.localOffset + 1) * LIMIT;
      const data = {
        token: this.token,
        offsetValue: newOffset,
        searchValue: this.searchValue,
      };
      await this.getAllFromAPI(data);
      this.scrollToTop();
    },
    async getPoints(offset) {
      const data = {
        token: this.token,
        offsetValue: offset * LIMIT,
        searchValue: this.searchValue,
      };
      try {
        await this.getAllFromAPI(data);
      } catch (error) {
        this.$router.push({ name: 'NotFoundPage' });
      }
      this.scrollToTop();
    },
  },
  async beforeMount() {
    await this.pages;
  },
};
</script>
