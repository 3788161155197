const setNewCheckpointToState = (state, checkpoint) => {
  state.checkpoints.push(checkpoint);
};
const setCheckpointToState = (state, checkpoint) => {
  state.checkpoint = checkpoint;
};
const setAllCheckpointsToState = (state, checkpoints) => {
  state.checkpoints = checkpoints;
};
const deleteCheckpointFromState = (state, id) => {
  const { checkpoints } = state;
  for (let index = 0; index < checkpoints.length; index += 1) {
    const item = checkpoints[index];
    if (item.id === +id) checkpoints.splice(index, 1);
  }
};

const updateCheckpoint = (state, newData) => {
  const { checkpoints } = state;
  for (let index = 0; index < checkpoints.length; index += 1) {
    const item = checkpoints[index];
    if (item.id === newData.id) {
      const str = newData.checkpoint.data.coordinates;
      const pos = str.search(',');
      const x = str.slice(0, pos);
      const y = str.slice(pos + 1);
      checkpoints[index] = newData.checkpoint;
      checkpoints[index].data.latitude = x;
      checkpoints[index].data.longitude = y;
    }
  }
};

const setCheckpointPagesNumbers = (state, pagesNumbers) => {
  state.pagesNumbers = pagesNumbers;
};

const setCheckpointLocalOffset = (state, offset) => {
  state.localOffset = offset;
};

const setCheckpointSearchValue = (state, searchValue) => {
  state.searchValue = searchValue;
};

export default {
  setNewCheckpointToState,
  setCheckpointToState,
  deleteCheckpointFromState,
  updateCheckpoint,
  setAllCheckpointsToState,
  setCheckpointPagesNumbers,
  setCheckpointSearchValue,
  setCheckpointLocalOffset,
};
