<template>
  <form
    id="form"
    @submit.prevent="save"
    v-show="formVisibility"
    class="form page__content">
    <map-vue
      :coordinates="coordinates"
    ></map-vue>
    <div class="form__container">
      <label
        for="coordinates"
        class="text-white">Координати чекпоінту*</label>
      <input
        id="coordinates"
        class="input"
        type="text"
        v-model="coordinates"
        placeholder="50.47773924685188, 30.50056940795161"
        :class="{'input--error': coordinates.length === 0}"
        required>
    </div>
    <div class="flex">
      <section class="form__section">
        <div class="form__container">
          <label
            for="name"
            class="text-white">Назва рекламного чекпоінту*</label>
          <!-- eslint-disable max-len -->
          <textarea
            id="name"
            cols="30"
            rows="2"
            class="text textarea"
            v-model="banner.data.name"
            @keyup="changeTextareaSize('name')"
            maxlength="500"
            :class="{'input--error': banner.data.name.length === 0}"
            placeholder="Автостанція “Рафік” або інша назва, яку можна писати в декілька рядків. (макс. довжина: 500 символів)"
            required></textarea>
        </div>
        <div class="form__container">
          <label
            for="contacts"
            class="text-white">Контакти</label>
          <textarea
            name="contacts"
            id="contacts"
            cols="30"
            rows="2"
            class="text textarea"
            v-model="banner.data.contacts"
            @keyup="changeTextareaSize('contacts')"
            maxlength="500"
            placeholder="+38 099 999 99 99
Рафік (макс. довжина: 500 символів)"></textarea>
        </div>
        <div class="form__container">
          <label
            for="address"
            class="text-white">Адреса</label>
          <textarea
            name="address"
            id="address"
            cols="30"
            rows="2"
            class="text textarea"
            v-model="banner.data.address"
            @keyup="changeTextareaSize('address')"
            maxlength="500"
            placeholder="Вул.Межигірська 89, м.Київ. (макс. довжина: 500 символів)"></textarea>
        </div>
        <div class="form__container">
          <div class="text input_image-wrapper" data-text="Завантажити зображення">
            <input
              @change="setPicture"
              name="form-input-image-field"
              type="file"
              id="file"
              class="input_image-input"
              accept="image/*"
              value="">
          </div>
          <img class="preview" :src="banner.data.image" alt="no file">
        </div>
      </section>
      <section class="form__section">
        <label for="description" class="text-white">Опис рекламного чекпоінту</label>
        <textarea
          name="description"
          id="description"
          cols="30"
          rows="10"
          @keyup="changeTextareaSize('description')"
          class="text textarea--description"
          v-model="banner.data.description"
          maxlength="500"
          placeholder="Крута автостанція яка замовила рекламу в додатку автоматично отримує таку шпильку, яка додається на карту. При прокрутці карти користувач має змогу нажати на шпильку та вилізу я, опис бізнесу та інформація.

- Послуга 1
- Послуга 2
- Витерти скло
- Пристібнутись
- Посіяти буряк

максимальна довжина: 500 символів"></textarea>
      </section>
    </div>
    <p class="text-white--option">* - Обов'язкові поля</p>
    <p class="text-white--option">Максимальна довжина: 500 символів</p>
    <button
      class="text-white button-submit"
      type="submit">Надіслати</button>
    <button
      class="text-white button-submit"
      type="button"
      @click.stop="cancel">Скасувати</button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MapVue from '../../../utils/UI/Map.vue';

export default {
  name: 'EditAdvertisingBannerForm',
  components: {
    mapVue: MapVue,
  },
  data() {
    return {
      banner: {},
      formVisibility: true,
      coordinates: '',
    };
  },
  methods: {
    ...mapActions({
      getFromAPI: 'getBannerFromAPIById',
      seveUpdated: 'updateBanner',
    }),
    setCoordinates() {
      this.banner.data.coordinates = this.coordinates;
    },
    setPicture() {
      const file = document
        .querySelector('input[type=file]')
        .files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.banner.data.image = e.target.result;
      };
      reader.onerror = (error) => {
        console.log(error);
      };
      reader.readAsDataURL(file);
    },
    async save() {
      this.setCoordinates();
      const data = {
        id: this.$route.params.id,
        token: this.getToken,
        banner: this.banner,
      };
      if (this.coordinates.length !== 0 && this.banner.data.name.length !== 0) {
        // eslint-disable-next-line no-alert
        alert('Рекламний банер оновлено');
        this.cancel();
        await this.seveUpdated(data);
      }
    },
    cancel() {
      this.formVisibility = false;
      this.$router.go(-1);
    },
    changeTextareaSize(tagId) {
      const textarea = document.getElementById(tagId);
      if (textarea.scrollTop > 0) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
  },
  computed: {
    ...mapGetters({
      getFromState: 'getBanner',
      getToken: 'getToken',
    }),
  },
  async beforeMount() {
    const data = {
      id: this.$route.params.id,
      token: this.getToken,
    };
    await this.getFromAPI(data);
    this.banner = this.getFromState;
    this.banner.data.old_image = this.banner.data.image;
    this.coordinates = `${this.banner.data.latitude}, ${this.banner.data.longitude}`;
  },
};
</script>

<style lang="sass" scoped>
@import '../../../assets/main'

.preview
  max-height: 15rem
@media (max-width: 965px) and ( min-width: 817px)
  .preview
    max-width: 14rem
@media  (max-width: 818px) and (min-width: 0)
  .preview
    max-width: 11rem

.flex
  display: flex
  justify-content: space-between
</style>
