<template>
  <edit-form></edit-form>
</template>

<script>
import EditAdvertisingBannerFormVue from '../../components/Forms/advertisingBanner/EditAdvertisingBannerForm.vue';

export default {
  name: 'EditAdvertisingBannerPage',
  components: {
    editForm: EditAdvertisingBannerFormVue,
  },
};
</script>
