import axios from 'axios';
import urls from '@/utils/constants/index';

const { POINT_URL } = urls;

const postBannerToAPI = ({ commit }, data) => new Promise((resolve, reject) => {
  axios.post(`${POINT_URL}/add`, data.banner, { headers: { 'admin-token': data.token } })
    .then((response) => {
      commit('setNewBannerToState', data.banner);
      resolve(response);
    })
    .catch((error) => {
      console.error(error.message);
      reject(error);
    });
});

const getBannerFromAPIById = ({ commit }, data) => new Promise((resolve, reject) => {
  axios.get(`${urls.POINT_URL}/${data.id}`, { headers: { 'admin-token': data.token } })
    .then((response) => {
      const banner = response.data;
      commit('setBannerToSate', banner);
      resolve(response);
    })
    .catch((error) => {
      console.error(error.message);
      reject(error);
    });
});

const getAllBannersFromAPI = ({ commit }, token) => new Promise((resolve, reject) => {
  axios.get(`${urls.POINT_URL}`, { headers: { 'admin-token': token } })
    .then((response) => {
      commit('setAllBannersToState', response.data);
      resolve(response);
    })
    .catch((error) => {
      console.error(error.message);
      reject(error);
    });
});
const updateBanner = ({ commit }, data) => new Promise(() => {
  axios.patch(`${urls.POINT_URL}/${data.id}`, data.banner, { headers: { 'admin-token': data.token } })
    .then(() => {
      commit('updateBannerInState', data);
    })
    .catch((error) => {
      console.error(error.message);
    });
});
const deleteBannerFromAPIById = ({ commit }, data) => new Promise(() => {
  axios.delete(`${urls.POINT_URL}/${data.id}`, { headers: { 'admin-token': data.token } })
    .then(() => {
      commit('deleteBannerFromState', data.id);
    })
    .catch((error) => {
      console.error(error.message);
    });
});

const searchBannsers = ({ commit }, data) => new Promise((resolve, reject) => {
  axios.get(`${POINT_URL}?limit=100&offset=${data.offsetValue}&search=${data.searchValue}`, { headers: { 'admin-token': data.token } })
    .then((response) => {
      if (data.offsetValue !== 0) {
        commit('setBannerLocalOffset', +data.offsetValue / 100);
      } else commit('setBannerLocalOffset', 0);
      commit('setBannerSearchValue', data.searchValue);
      commit('setAllBannersToState', response.data);
      commit('setBannerPagesNumbers', response.data.numberPages);
      resolve(response);
    })
    .catch((error) => reject(error));
});

export default {
  postBannerToAPI,
  getBannerFromAPIById,
  getAllBannersFromAPI,
  updateBanner,
  deleteBannerFromAPIById,
  searchBannsers,
};
