<template>
  <div class="page__content">
    <filterForm/>
    <table class="list-table list-table__element page__content">
      <tr>
        <th class="text-white list-table__title"><p>Координати</p></th>
        <th class="text-white list-table__title"><p>Заголовок сповіщення</p></th>
        <th class="text-white list-table__title"><p>Текст сповішення</p></th>
      </tr>
      <template v-for="banner in banners" :key="banner.id">
        <tr>
          <td>
            <p class="text list-table__item-text">
              {{banner.data.latitude}},
              {{banner.data.longitude}}
            </p>
          </td>
          <td>
            <p class="text list-table__item-text">{{banner.data.name}}</p>
          </td>
          <td>
            <p class="text list-table__item-text">{{banner.data.description}}</p>
          </td>
        </tr>
        <tr class="list-table__element">
          <td colspan="3" class="list-table__element">
            <div class="list-table__actions-container">
              <img class="list-table__element_image" :src="banner.data.image" alt="image not found">
              <div class="flex">
                <router-link
                  class="action-button--edit text text-white"
                  :to="{ name: 'EditAdertisingBanner', params: { id: banner.id} }"
                  tag="a">Редагування</router-link>
                <del-button :idItem="banner.id" :type="banner.type"></del-button>
                <!-- action-button--edit -->
              </div>
            </div>
          </td>
        </tr>
      </template>
    </table>
    <pagesNav/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DeleteButtonVue from '../ActionButtons/DeleteButton.vue';
import BannerListFilterVue from '../Forms/listFilter/BannerListFilter.vue';
import BannerPagesNavVue from '../pagesNav/BannerPagesNav.vue';

export default {
  name: 'AdvertisingBannerList',
  components: {
    delButton: DeleteButtonVue,
    filterForm: BannerListFilterVue,
    pagesNav: BannerPagesNavVue,
  },
  data() {
    return {
      banners: [],
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'getToken',
      getAllFromState: 'getAllBanners',
    }),
  },
  async beforeMount() {
    const token = this.getToken;
    try {
      await this.getAllFromAPI(token);
    } catch (error) {
      this.$router.push({ name: 'NotFoundPage' });
    }
    this.banners = this.getAllFromState;
  },
  beforeUnmount() {
    this.setOffset('');
    this.setPagesNumbers(0);
    this.setSearchValue('');
  },
  methods: {
    ...mapActions({
      getAllFromAPI: 'getAllBannersFromAPI',
    }),
    ...mapMutations({
      setOffset: 'setBannerLocalOffset',
      setPagesNumbers: 'setBannerPagesNumbers',
      setSearchValue: 'setBannerSearchValue',
    }),
  },
};
</script>

<style lang="sass" scoped>
.list-table
  width: 100%
</style>
