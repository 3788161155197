const getAllCheckpointsFromState = (state) => state.checkpoints;
const getCheckpointFromState = (state) => state.checkpoint;
const getCheckpointPagesNumbers = (state) => state.pagesNumbers;
const getCheckpointSearchValue = (state) => state.searchValue;
const getCheckpointLocalOffset = (state) => state.localOffset;

export default {
  getAllCheckpointsFromState,
  getCheckpointFromState,
  getCheckpointPagesNumbers,
  getCheckpointSearchValue,
  getCheckpointLocalOffset,
};
