<template>
  <list></list>
</template>

<script>
import CheckpointsListVue from '../../components/Lists/CheckpointsList.vue';

export default {
  name: 'CheckpointsListPage',
  components: {
    list: CheckpointsListVue,
  },
};
</script>
