import { TOKEN_NAME } from '../../utils/constants';
import cookies from '../../utils/cookies';

const setToken = (state, token) => {
  state.token = token;
  cookies.set(TOKEN_NAME, token);
};

const removeToken = (state) => {
  state.token = '';
  cookies.remove(TOKEN_NAME);
};

export default {
  setToken,
  removeToken,
};
