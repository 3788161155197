<template>
  <main class="page">
    <menu-vue></menu-vue>
    <router-view></router-view>
  </main>
</template>

<script>
import MenuVue from '../utils/UI/Menu.vue';

export default {
  components: {
    menuVue: MenuVue,
  },
};
</script>

<style lang="sass" scoped>
@import '../assets/main'

.page
  width: 100%
  // height: 100vh
  display: flex
</style>
