<template>
  <button class="text text-white action-button--del" @click="remove">Видалити</button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DelteButton',
  props: {
    idItem: Number,
    type: String,
  },
  computed: {
    ...mapGetters({
      token: 'getToken',
    }),
    getData() {
      return {
        token: this.token,
        id: this.idItem,
      };
    },
  },
  methods: {
    ...mapActions({
      removeCheckpointFromAPI: 'deleteCheckpointFromAPIById',
      removeBannerFromAPI: 'deleteBannerFromAPIById',
    }),
    remove() {
      // eslint-disable-next-line no-alert
      const ready = window.confirm('Ти впевнений');
      if (ready) {
        if (this.type === 'camera') this.removeCheckpointFromAPI(this.getData);
        else if (this.type === 'advertisement') this.removeBannerFromAPI(this.getData);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.button
  border: none

</style>
