<template>
  <adding-form></adding-form>
</template>

<script>
import NewCheckpointForm from '../../components/Forms/checkpoint/NewCheckpointForm.vue';

export default {
  name: 'NewCheckpointPage',
  components: {
    addingForm: NewCheckpointForm,
  },
};
</script>
