<template>
  <form @submit.prevent="find" class="form--find">
    <div class="form__container--find">
      <input
        class="input"
        type="text"
        v-model="searchValue"
        placeholder="50.47773924685188, 30.50056940795161"
      >
    </div>
    <button class="text-white button-submit button-search" type="submit">Пошук</button>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CheckpointListFilter',
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'getToken',
    }),
  },
  methods: {
    ...mapActions({
      searchCheckpoint: 'searchCheckpoint',
    }),
    ...mapMutations({
      setOffset: 'setCheckpointLocalOffset',
    }),
    find() {
      const data = {
        searchValue: this.searchValue,
        offsetValue: 0,
        token: this.getToken,
      };
      this.$router.push({
        name: 'CheckpointsListPage', params: { pageNumder: 1 },
      });
      this.searchCheckpoint(data);
    },
  },
};
</script>

<style lang="sass" scoped>
.form--find
  display: flex
  flex-direction: row
  width: 100%

.form__container--find
  width: 80%

.button-submit
  margin-top: 0
  margin-bottom: 0
  height: 38px

.button-search
  width: fit-content
  height: fit-content

  line-height: 21px
  padding: 9px 31px

.input
  margin-top: 0
  height: 40px
</style>
