import { createRouter, createWebHistory } from 'vue-router';
import cookies from '../utils/cookies/index';
import { TOKEN_NAME } from '../utils/constants/index';
import Home from '../views/Home.vue';
import LoginPage from '../views/LoginPage.vue';
import NewCheckpointPage from '../views/checkpoints/NewCheckpointPage.vue';
import EditCheckpointPage from '../views/checkpoints/EditCheckpointPage.vue';
import CheckpointsListPage from '../views/checkpoints/CheckpointsListPage.vue';
import NewAdertisingBannerPage from '../views/advertisingBanner/NewAdvertisingBannerPage.vue';
import EditAdvertisingBannerPage from '../views/advertisingBanner/EditAdvertisingBannerPage.vue';
import AdvertisingBannersListPage from '../views/advertisingBanner/AdvertisingBannersListPage.vue';
import Logout from '../components/Logout.vue';
import PushMessageForm from '../components/Forms/PushMessageForm.vue';
import NotFoundPage from '../views/NotFoundPage.vue';

const ifNotAuthenticated = (to, from, next) => {
  const token = cookies.get(TOKEN_NAME);
  if (token) {
    next();
    return;
  }
  next('/not-found');
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: ifNotAuthenticated,
    children: [
      {
        path: '/checkpoint/new',
        name: 'NewCheckpoint',
        component: NewCheckpointPage,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: '/checkpoint/edit/:pageNumder',
        name: 'CheckpointsListPage',
        component: CheckpointsListPage,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: '/checkpoint/edit/:id',
        name: 'EditCheckpoint',
        component: EditCheckpointPage,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: '/banner/new',
        name: 'NewAdertisingBanner',
        component: NewAdertisingBannerPage,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: '/banner/edit/:pageNumder',
        name: 'AdertisingBannersList',
        component: AdvertisingBannersListPage,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: '/banner/edit/:id',
        name: 'EditAdertisingBanner',
        component: EditAdvertisingBannerPage,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: '/push-message/new',
        name: 'PushMessageForm',
        component: PushMessageForm,
        beforeEnter: ifNotAuthenticated,
      },
      {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        beforeEnter: ifNotAuthenticated,
      },
    ],
  },
  {
    path: '/ka7625cb',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundPage,
    name: 'NotFound',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
