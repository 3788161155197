function validPassword(password) {
  let isValid = false;
  // const regLowerCaseUppercaseNumber = new RegExp(
  // '^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s).*|(?=.*\\d)(?=.*[а-я])(?=.*[А-Я])(?!.*\\s).*)$');
  if (password.length > 6) {
    isValid = true;
  } else if (password === '' || !password) {
    isValid = false;
  }
  return isValid;
}

function cleanErrorArray(array, message) {
  const index = array.indexOf(message);
  if (index !== -1) {
    array.splice(index, 1);
  }
}

function setMessage(array, message, isValid) {
  cleanErrorArray(array, message);
  if (isValid === false) {
    array.push(message);
  } else {
    cleanErrorArray(array, message);
  }
  return array;
}

module.exports = {
  validPassword,
  cleanErrorArray,
  setMessage,
};
