<template>
<!-- eslint-disable -->
  <form
    @submit.prevent="submit"
    class="form page__content"
    id="form">
    <div class="form__section">
      <label
        for="notificationTitle"
        class="text-white">Заголовок</label>
      <input
        id="notificationTitle"
        class="input"
        type="text"
        v-model="notificationTitle"
        placeholder="Добавлені нові камері в Києві">
    </div>
    <div class="form__section form__section--top2rem">
        <label
          for="notificationTitle"
          class="text-white">Текст сповіщення</label>
        <textarea
          placeholder="Крута автостанція яка замовила рекламу в додатку автоматично отримує таку шпильку, яка додається на карту. При прокрутці карти користувач має змогу нажати на шпильку та вилізу я, опис бізнесу та інформація."
          name="notificationMessage"
          id="notificationMessage"
          class="text textarea"
          v-model="notificationMessage"
          cols="30"
          rows="2"
          maxlength="500"
          :class="{'input--error': notificationMessage.length === 0}"
          required></textarea>
    </div>
    <p class="text-red">{{ error }}</p>
    <button type="submit" class="text-white button-submit">Надіслати</button>
  </form>
</template>

<script>
// eslint-enable
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PushMessageForm',
  data() {
    return {
      notificationTitle: '',
      notificationMessage: '',
      error: '',
    };
  },
  computed: {
    ...mapGetters({
      token: 'getToken',
    }),
  },
  methods: {
    ...mapActions({
      post: 'postPushMessage',
    }),
    getData() {
      const data = {
        message: this.notificationMessage,
      };
      if (this.notificationTitle.length !== 0) {
        data.topic = this.notificationTitle;
      }
      return data;
    },
    submit() {
      if (this.error.length === 0) {
        const result = {
          token: this.token,
          messageData: this.getData(),
        };
        this.post(result);
      }
      this.reset();
    },
    reset() {
      document.getElementById('form').reset();
      this.notificationMessage = '';
      this.notificationTitle = '';
    },
  },
};
</script>

<style lang="sass" scoped>
.form__section
  width: 100%
  &--top2rem
    margin-top: 2rem

.textarea
  width: 100%
  height: 20rem
</style>
