<template>
  <edit-form></edit-form>
</template>

<script>
import EditCheckpointForm from '../../components/Forms/checkpoint/EditChekpointForm.vue';

export default {
  name: 'EditCheckpointPage',
  components: {
    editForm: EditCheckpointForm,
  },
};
</script>
