function set(name, value, options = {}) {
  // eslint-disable-next-line
  options = {
    ...options,
  };
  // eslint-disable-next-line
  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  // eslint-disable-next-line
  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `= ${optionValue}`;
    }
  }
  document.cookie = updatedCookie;
}

function get(name) {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function remove(name) {
  set(name, '', { 'max-age': -1 });
}

export default {
  set,
  get,
  remove,
};
