import { createStore } from 'vuex';
import auth from './auth/index';
import checkpoint from './checkpoit/index';
import banner from './banner/index';
import pushMessage from './push-message/index';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    checkpoint,
    banner,
    pushMessage,
  },
});
