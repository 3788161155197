// const BASE_URL = 'https://speedua.works/api';
const BASE_URL = process.env.VUE_APP_API_URL;
const LOGIN_PAGE_URL = 'ka7625cb';
const TEST_URL = `${BASE_URL}/test`;
const AUTH_URL = `${BASE_URL}/${LOGIN_PAGE_URL}`;
const POINT_URL = `${BASE_URL}/points`;
const PUSH_MESSAGE_URL = `${BASE_URL}/notifications`;
export const LIMIT = 100;
export const TOKEN_NAME = 'pyNDcRYmQQ';

export default {
  BASE_URL,
  AUTH_URL,
  POINT_URL,
  TEST_URL,
  PUSH_MESSAGE_URL,
  LOGIN_PAGE_URL,
};
