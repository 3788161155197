<template>
<div class="page__content">
  <filterForm/>
  <table class="list-table list-table__element page__content">
    <tr>
      <th class="text-white list-table__title"><p>Координати</p></th>
      <th class="text-white list-table__title"><p>Заголовок сповіщення</p></th>
      <th class="text-white list-table__title"><p>Текст сповішення</p></th>
    </tr>
    <template v-for="(checkpoint, id) in checkpoints" :key="id">
      <tr>
        <td>
          <p class="text list-table__item-text">
            {{checkpoint.data.latitude}},
            {{checkpoint.data.longitude}}
          </p>
        </td>
        <td>
          <span class="text list-table__item-text">{{checkpoint.data.title}}</span>
        </td>
        <td>
          <span class="text list-table__item-text">{{checkpoint.data.text}}</span>
        </td>
      </tr>
      <tr class="list-table__element">
        <td colspan="3" class="list-table__element">
          <div class="list-table__actions-container">
            <img class="speed-type"
              :src="getSpeedTypeImageUrl(checkpoint.data.speed_type)"
              :alt="checkpoint.data.speed_type">
            <div class="actions-container_actions">
              <router-link
                class="action-button--edit text text-white"
                :to="{ name: 'EditCheckpoint', params: { id: checkpoint.id} }"
                tag="a">Редагування</router-link>
              <del-button
                :idItem="checkpoint.id"
                :type="checkpoint.type"></del-button>
            </div>
          </div>
        </td>
      </tr>
    </template>
  </table>
  <pagesNav/>
</div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DeleteButtonVue from '../ActionButtons/DeleteButton.vue';
import CheckpointListFilter from '../Forms/listFilter/CheckpointListFilter.vue';
import PagesNavVue from '../pagesNav/PagesNav.vue';

export default {
  name: 'CheckpointList',
  components: {
    delButton: DeleteButtonVue,
    filterForm: CheckpointListFilter,
    pagesNav: PagesNavVue,
  },
  computed: {
    ...mapGetters({
      checkpoints: 'getAllCheckpointsFromState',
      searchValue: 'getCheckpointSearchValue',
      token: 'getToken',
    }),
  },
  async beforeMount() {
    const data = {
      token: this.token,
      offsetValue: 0,
      searchValue: this.searchValue,
    };
    try {
      await this.getAllFromAPI(data);
    } catch (error) {
      this.$router.push({ name: 'NotFound' });
    }
  },
  beforeUnmount() {
    this.setOffset('');
    this.setPagesNumbers(0);
    this.setSearchValue('');
  },
  methods: {
    ...mapActions({
      getAllFromAPI: 'searchCheckpoint',
    }),
    ...mapMutations({
      setOffset: 'setCheckpointLocalOffset',
      setPagesNumbers: 'setCheckpointPagesNumbers',
      setSearchValue: 'setCheckpointSearchValue',
    }),
    getSpeedTypeImageUrl(speedType) {
      return `${process.env.VUE_APP_API_URL}/image/speedPoints?type=${speedType}`;
    },
  },
};
</script>

<style lang="sass" scoped>
.list-table
  width: 100%
</style>
