<template>
  <article class="notification-preview">
    <img
      class="notification-preview_img"
        :src="imageSrc"
        alt="notification preview">
    <section class="notification-preview_text-container">
      <h2 class="text-white text-container__title"
        v-if="notificationTitle === ''">ПОПЕРЕДУ КАМЕРА</h2>
      <h2 class="text-white text-container__title"
        v-if="notificationTitle !== ''">{{ notificationTitle }}</h2>
      <h2 class="text-white text-container__text"
        v-if="notificationText === ''">БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 50КМ/ГОД</h2>
      <h2 class="text-white text-container__text"
        v-if="notificationText !== ''">{{ notificationText }}</h2>
    </section>
  </article>
</template>

<script>
export default {
  name: 'NotificationPreview',
  props: {
    imageSrc: String,
    notificationTitle: String,
    notificationText: String,
  },
};
</script>
