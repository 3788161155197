<template>
  <nav class="menu">
    <button class="menu__button" @click="showMenu">
      <img
        id="menu-icon"
        class="menu-icon"
        src="../../../public/images/menu-icon.png"
        alt="menu icon">
    </button>
    <div class="menu__links" id="links">
        <div class="links_logo-container">
          <img class="links_logo-container__image"
               src="../../../public/images/logo-small.svg" alt="logo image">
          <img class="links_logo-container__name"
               src="../../../public/images/company-name.svg" alt="speedua">
        </div>
        <div @click="closeMenu"
             @click.prevent="click('checkpoint-new')">
          <router-link
            ref="checkpointNew"
            exact-active-class="menu__link--active"
            class="menu__link text-white"
            to="/checkpoint/new"
            >Створити чекпоінт</router-link>
        </div>
      <hr class="line">
      <div @click="closeMenu"
           @click.prevent="click('banner-new')">
        <router-link
          exact-active-class="menu__link--active"
          class="menu__link text-white"
          to="/banner/new"
          >Створити рекламний чекпоінт</router-link>
      </div>
      <hr class="line">
      <div @click="closeMenu"
           @click.prevent="click('checkpoint-edit')">
        <router-link
          exact-active-class="menu__link--active"
          class="menu__link text-white"
          :to="{
            name: 'CheckpointsListPage', params: { pageNumder: 1 }
          }"
          >Редагування чекпоінтів</router-link>
      </div>
      <hr class="line">
      <div @click="closeMenu"
           @click.prevent="click('banner-edit')">
        <router-link
          exact-active-class="menu__link--active"
          class="menu__link text-white"
          :to="{
            name: 'AdertisingBannersList', params: { pageNumder: 1 }
          }"
          >Редагування рекламних чекпоінтів</router-link>
      </div>
      <hr class="line">
      <div @click="closeMenu"
           @click.prevent="click('push-message-new')">
        <router-link
          exact-active-class="menu__link--active"
          class="menu__link text-white"
          to="/push-message/new"
          >Надіслати push-повідомлення</router-link>
      </div>
      <hr class="line">
      <div @click="closeMenu"
           @click.prevent="click('logout')">
        <router-link
          exact-active-class="menu__link--active"
          class="menu__link text-white"
          to="/logout"
          >Вихід</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Menu',
  methods: {
    showMenu() {
      const link = document.getElementById('links');
      if (link.style.display === 'block') {
        link.style.display = 'none';
      } else {
        link.style.display = 'block';
      }
    },
    closeMenu() {
      const menuIcon = document.getElementById('menu-icon');
      if (menuIcon.style.display !== 'none') {
        const link = document.getElementById('links');
        if (link.style.display === 'block') {
          link.style.display = 'none';
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../assets/main'

$menu-padding-left-and-right: 2rem
$menu-padding-top: 4rem
.links_logo-container
  display: flex
  flex-direction: column
  align-items: center
  margin-right: 2rem
  margin-bottom: 1.4rem
  height: auto
  &__image
    height: 50%
    margin-bottom: 1.4rem
  &__name
    height: 50%

.menu
  width: 19rem
  position: fixed
  padding-top: $menu-padding-top
  padding-left: $menu-padding-left-and-right
  background-color: $dark-gray
  z-index: 100
  &__button
    @include animation
    background: none
    border: none
    cursor: pointer
  &__link
    @include animation
    display: block
    text-decoration: none
    padding-left: .63rem
    padding-top: 1.4rem
    padding-bottom: 1.4rem
    padding-right: 2rem
    &:hover
      background-color: #605E5E !important
    &:focus
      outline: none
  &-icon
    display: none

.menu__link--active
  background-color: #605E5E

@media (min-width: 900px)
  .menu
    min-height: 100%
@media (max-width: 900px) and (min-width: 964px)
  .menu
    width: 100%
    min-height: auto
    position: fixed
    padding: 2rem
    &-icon
      height: 1rem
      display: block
    &__links
      display: none
    &__link
      padding-right: .9rem
@media (max-width: 965px) and (min-width: 817px)
  .links_logo-container
    display: none
  .menu
    width: 100%
    min-height: auto
    position: fixed
    padding: 2rem
    &-icon
      height: 1rem
      display: block
    &__links
      display: none
    &__link
      padding-right: .9rem
@media (max-width: 818px) and (min-width: 599px)
  .links_logo-container
    display: none
  .menu
    width: 100%
    min-height: auto
    position: fixed
    padding: 2rem
    &-icon
      height: 1rem
      display: block
    &__links
      display: none
    &__link
      padding-right: .9rem
@media (max-width: 600px) and (min-width: 0px)
  .menu
    width: 100%
    min-height: auto
    position: fixed
    padding: 2rem
    &-icon
      height: 1rem
      display: block
    &__links
      display: none
    &__link
      padding-right: .9rem
  .links_logo-container
    display: none

</style>
