<template>
  <div class="page__content">
    <h1 class="text-white">Точно хочеш піти?</h1>
    <div class="flex">
      <button
        tag="a"
        class="menu__link menu__link--yes text-white"
        @click="logout">Так</button>
      <router-link
        tag="a"
        to='/checkpoint/new'
        class="menu__link menu__link--no text-white"
        >Ні</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  methods: {
    ...mapActions({
      logoutFromApp: 'logout',
    }),
    async logout() {
      this.$router.push({ name: 'Login' });
      await this.logoutFromApp();
    },
  },
};
</script>

<style lang="sass" scoped>
@import './../assets/main'

.page__content
  margin-top: 20%
.text-white
  text-align: center
  margin-bottom: 2rem
.flex
  justify-content: space-evenly
.menu__link
  padding: 1rem 3rem
  text-align: center
  vertical-align: middle
  text-decoration: none
  &--yes
    border: none
    background: $blue
  &--no
    background: #d32731
@media (max-width: 1130px)
  .page__content
    margin-top: 7.4rem
</style>
