<template>
  <img class="text-white map-image" :src="imageURL" alt="map here">
</template>

<script>
export default {
  name: 'MapVue',
  props: {
    coordinates: String,
  },
  computed: {
    key() {
      return 'AIzaSyAs--14Y06VhYWahbf3fGb89f1ntm1Go10';
      // return 'AIzaSyDHLIkGVQ7Ps2X9lsArjSofyYsT50q2ZCA';
    },
  },
  data() {
    return {
      imageURL: '',
      zoom: 21,
      size: '640x300&scale=2',
    };
  },
  beforeMount() {
    this.setParamsByWindowWidth();
    this.imageURL = `https://maps.googleapis.com/maps/api/staticmap?center=${this.coordinates}&markers=${this.coordinates}&zoom=${this.zoom}&size=${this.size}&key=${this.key}`;
  },
  updated() {
    this.setParamsByWindowWidth();
    let url = 'https://maps.googleapis.com/maps/api/staticmap?';
    url += `center=${this.coordinates}`;
    url += '&style=visibility:on';
    url += `&markers=${this.coordinates}`;
    url += `&zoom=${this.zoom}`;
    url += `&size=${this.size}`;
    url += '&maptype=roadmap';
    url += `&key=${this.key}`;
    this.imageURL = url;
  },
  methods: {
    setParamsByWindowWidth() {
      const { width } = window.screen;
      if (width < 800) {
        this.zoom = 17;
        this.size = '600x600';
      } else if (width > 801 && width < 1500) {
        this.zoom = 15;
        this.size = '640x300&scale=2';
      } else {
        this.zoom = 15;
        this.size = '640x200';
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.map-image
  margin: 0 auto
  display: block
  margin-bottom: 1rem
@media (max-width: 1024px)
  .map-image
    width: 100%
@media (min-width: 1025px)
  .map-image
    width: 90%
</style>
