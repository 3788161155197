<template>
  <form
    id="form"
    @submit.prevent="save"
    class="form page__content">
    <map-vue
      :coordinates="coordinates || '50.47773924685188, 30.50056940795161'"
    ></map-vue>
    <div class="form__container">
      <label
        for="coordinates"
        class="text-white">Координати чекпоінту*</label>
      <input
        id="coordinates"
        class="input"
        type="text"
        v-model="coordinates"
        placeholder="50.47773924685188, 30.50056940795161"
        :class="{'input--error': coordinates.length === 0}"
        required>
    </div>
    <div class="form_row">
      <section class="form__section">
        <div class="form__container">
          <label
            for="title"
            class="text-white">Назва рекламного чекпоінту*</label>
          <!-- eslint-disable max-len -->
          <textarea
            name="title"
            id="title"
            cols="30"
            rows="2"
            class="text textarea"
            @focus="resize"
            @keyup="changeTextareaSize('title')"
            v-model="title"
            maxlength="500"
            :class="{'input--error': title.length === 0}"
            placeholder="Автостанція “Рафік” або інша назва, яку можна писати в декілька рядків.(макс. довжина: 500 символів)"
            required></textarea>
        </div>
        <div class="form__container">
          <label
            for="contacts"
            class="text-white">Контакти</label>
          <textarea
            name=""
            id="contacts"
            cols="30"
            rows="2"
            @focus="resize"
            @keyup="changeTextareaSize('contacts')"
            class="text textarea"
            v-model="contacts"
            maxlength="500"
            placeholder="+38 099 999 99 99
Рафік (макс. довжина: 500 символів)"></textarea>
        </div>
        <div class="form__container">
          <label
            for="address"
            class="text-white">Адреса</label>
          <textarea
            name=""
            id="address"
            cols="30"
            rows="2"
            class="text textarea"
            @keyup="changeTextareaSize('address')"
            v-model="address"
            maxlength="500"
            placeholder="Вул.Межигірська 89, м.Київ.(макс. довжина: 500 символів)"></textarea>
        </div>
        <div class="form__container">
          <div class="text input_image-wrapper" data-text="Завантажити зображення">
            <input
              @change="setPicture"
              name="form-input-image-field"
              type="file"
              class="text text-white input_image-input"
              accept="image/*"
              value="">
          </div>
          <img class="preview" :src="previewImage.picture" alt="no file">
        </div>
      </section>
      <section class="form__section">
        <label for="description" class="text-white">Опис рекламного чекпоінту</label>
        <!-- eslint-disable max-len -->
        <textarea
          name=""
          id="description"
          cols="30"
          rows="10"
          @keyup="changeTextareaSize('description')"
          class="text textarea--description"
          v-model="description"
          maxlength="500"
          placeholder="Крута автостанція яка замовила рекламу в додатку автоматично отримує таку шпильку, яка додається на карту. При прокрутці карти користувач має змогу нажати на шпильку та вилізу я, опис бізнесу та інформація.

- Послуга 1
- Послуга 2
- Витерти скло
- Пристібнутись
- Посіяти буряк

максимальна довжина: 500 символів"></textarea>
      </section>
    </div>
    <p class="text-white--option">* - Обов'язкові поля</p>
    <p class="text-white--option">Максимальна довжина: 500 символів</p>
    <button type="submit" class="text-white button-submit">Надіслати</button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MapVue from '../../../utils/UI/Map.vue';

export default {
  name: 'NewAdvertisingBannerForm',
  components: {
    mapVue: MapVue,
  },
  data() {
    return {
      coordinates: '',
      title: '',
      contacts: '',
      address: '',
      image: '',
      description: '',
      previewImage: {
        picture: 'https://firebasestorage.googleapis.com/v0/b/green-peach.appspot.com/o/cell-kiev-res%2F%D0%9F%D0%BE%D0%B7%D0%BD%D0%B0%D1%87%D0%BA%D0%B0%20%D1%81%D1%82%D0%B0%D0%BD%D1%86%D1%96%D1%97.png?alt=media&token=4d3fe203-a7bd-4069-b7e5-5436b1638c4d',
        imageData: '',
      },
    };
  },
  methods: {
    ...mapActions({
      post: 'postBannerToAPI',
    }),
    async save() {
      const data = {
        banner: this.getNewBannerData,
        token: this.token,
      };
      if (this.coordinates.length !== 0 && this.title.length !== 0) {
        await this.post(data);
        // eslint-disable-next-line no-alert
        alert('новий рекламний банер створено');
        this.$router.push('/banner/edit');
      }
    },
    changeTextareaSize(tagId) {
      const textarea = document.getElementById(tagId);
      if (textarea.scrollTop > 0) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    },
    setPicture(event) {
      const file = event.target;
      this.previewImage.picture = URL.createObjectURL(file.files[0]);
      this.previewImage.imageData = file;
      this.setBase64();
    },
    setBase64() {
      const file = document
        .querySelector('input[type=file]')
        .files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.onerror = (error) => {
        console.log(error);
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    ...mapGetters({
      token: 'getToken',
    }),
    getNewBannerData() {
      return {
        type: 'advertisement',
        data: {
          coordinates: this.coordinates,
          name: this.title,
          description: this.description,
          contacts: this.contacts,
          address: this.address,
          image: this.image,
        },
      };
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../../assets/main'

.flex
  display: flex
  justify-content: space-between
  > section
    width: 48.4%

.preview
  max-height: 15rem
@media (max-width: 965px) and ( min-width: 817px)
  .preview
    max-width: 14rem
@media  (max-width: 818px) and (min-width: 0)
  .preview
    max-width: 11rem

.form__input_image
  display: block

</style>
