<template>
  <form
    @submit.prevent="saveCheckpoint"
    v-show="formVisibility"
    class="form page__content">
    <map-vue
      :coordinates="coordinates"
    ></map-vue>
    <div class="form__container">
      <label
        for="coordinates"
        class="text-white">Координати чекпоінту*</label>
      <input
        id="coordinates"
        class="input"
        type="text"
        v-model="coordinates"
        placeholder="50.47773924685188, 30.50056940795161"
        :class="{'input--error': coordinates.length === 0}"
        required>
    </div>
    <div class="form_row">
      <section class="form__section">
        <label
          for="notificationTitle"
          class="text-white">Заголовок сповіщення*</label>
          <textarea
            name="notificationTitle"
            id="notificationTitle"
            class="text textarea"
            v-model="checkpoint.data.title"
            placeholder="ПОПЕРЕДУ КАМЕРА (макс. довжина: 500 символів)"
            cols="30"
            rows="2"
            maxlength="500"
            :class="{'input--error': checkpoint.data.title.length === 0}"
            required></textarea>
      </section>
      <section class="form__section">
        <label
          for="notificationText"
          class="text-white">Текст сповішення*</label>
          <textarea
            name="notificationText"
            id="notificationText"
            class="text textarea"
            v-model="checkpoint.data.text"
            placeholder="БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 50КМ/ГОД (макс. довжина: 500 символів)"
            cols="30"
            rows="2"
            maxlength="500"
            :class="{'input--error': checkpoint.data.text.length === 0}"
            required></textarea>
      </section>
    </div>
    <div class="flex">
      <section class="form__section">
        <p class="text-white">Тип</p>
        <section class="form__container--type">
          <input
            class="radio"
            id="50км"
            type="radio"
            v-model="checkpoint.data.speed_type"
            value="50">
          <label for="50км" class="text text-white radio--text">50км/ч</label>
          <input
            class="radio"
            id="110км"
            type="radio"
            v-model="checkpoint.data.speed_type"
            value="110">
          <label for="110км" class="text text-white radio--text">110км/ч</label>
          <input
            class="radio"
            id="poliseStation"
            type="radio"
            v-model="checkpoint.data.speed_type"
            value="0">
          <label for="poliseStation" class="text text-white radio--poliseStation">
            Пост Поліції
          </label>
        </section>
      </section>

      <article class="notification-preview">
        <img
          class="notification-preview_img"
          :src="getImageSrc"
          alt="notification preview">
        <section class="notification-preview_text-container">
          <h2 class="text-white text-container__title"
            v-if=" checkpoint.data.title === ''">ПОПЕРЕДУ КАМЕРА</h2>
          <h2 class="text-white text-container__title"
            v-if=" checkpoint.data.title !== ''">{{ checkpoint.data.title }}</h2>
          <h2 class="text-white text-container__text"
            v-if=" checkpoint.data.text === ''">БУДЬТЕ ОБАЧНІ, ДОЗВОЛЕНА ШВИДКІСТЬ 50КМ/ГОД</h2>
          <h2 class="text-white text-container__text"
            v-if=" checkpoint.data.text !== ''">{{ checkpoint.data.text }}</h2>
        </section>
      </article>
    </div>
    <p class="text-white--option">* - Обов'язкові поля</p>
    <p class="text-white--option">Максимальна довжина: 500 символів</p>
    <button class="text-white button-submit" type="submit">Надіслати</button>
    <button
      class="text-white button-submit"
      type="button"
      @click.stop="cancel">Скасувати</button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MapVue from '../../../utils/UI/Map.vue';

export default {
  name: 'NewCheckpointForm',
  components: {
    mapVue: MapVue,
  },
  data() {
    return {
      checkpoint: {},
      formVisibility: true,
      coordinates: '',
    };
  },
  props: {
    idItem: Number,
  },
  computed: {
    ...mapGetters({
      getFromState: 'getCheckpointFromState',
      getToken: 'getToken',
    }),
    getImageSrc() {
      return `${process.env.VUE_APP_API_URL}/image/speedPoints?type=${this.checkpoint.data.speed_type}`;
    },
    getData() {
      return {
        id: this.$route.params.id,
        checkpoint: {
          type: this.checkpoint.type,
          data: {
            coordinates: this.coordinates,
            title: this.checkpoint.data.title,
            text: this.checkpoint.data.text,
            speed_type: this.checkpoint.data.speed_type,
          },
        },
        token: this.getToken,
      };
    },
  },
  async beforeMount() {
    const data = {
      id: this.$route.params.id,
      token: this.getToken,
    };
    await this.getCheckpointByIdFromAPI(data);
    this.checkpoint = this.getFromState;
    this.coordinates = `${this.checkpoint.data.latitude}, ${this.checkpoint.data.longitude}`;
  },
  methods: {
    ...mapActions({
      seveUpdated: 'updateCheckpoint',
      getCheckpointByIdFromAPI: 'getCheckpointFromAPIById',
    }),
    saveCheckpoint() {
      const data = this.getData;
      if (this.coordinates !== 0
      && this.checkpoint.data.text !== 0
      && this.checkpoint.data.title !== 0) {
        // eslint-disable-next-line no-alert
        alert('дані оновлено');
        this.seveUpdated(data);
        this.cancel();
      }
    },
    cancel() {
      this.formVisibility = false;
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="sass" scoped>
.form__section
  margin-bottom: 1.88rem
</style>
